import { motion } from 'framer-motion';
import { slideIn } from "../utils/motion";
import { AiFillInstagram, AiFillYoutube, AiOutlineArrowRight, AiOutlineTwitter } from 'react-icons/ai';
import Button from '../utils/Button';
import { SiTelegram } from 'react-icons/si';
import { FaTelegram } from 'react-icons/fa';

const Header = () => {

    return (<motion.div className="  text-[#000] bg-white border-b w-full border-gray-300 py-5 md:py-1  text-[16px] font-semibold flex  md:flex-row z-20 gap-5 items-center  justify-between md:justify-between px-10"
        variants={slideIn('up', 'spring', 0.1, 0.4)}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}

    >
         <header className=" md:w-[80%] flex flex-col justify-center items-center mx-auto py-4">
        <div className="container mx-auto">
          <h1 className=" uppercase  text-center text-4xl font-bold text-gray-800">First Pepe $FPEPE</h1>
        </div>
        <div>
            <div className="flex  justify-center underline font-bold flex-row gap-5">
                <a href="https://t.me/FirstPepeSolana">Telegram</a>
                <a href="https://x.com/FirstPepeSolana">Twitter</a>
                <a href="https://dexscreener.com/solana/#">Chart</a>
            </div>
            <p className='mx-auto text-center'>Contracts soon</p>
        </div>
      </header>

    </motion.div>);
}

export default Header;